if (window.matchMedia('(max-width: 959px)').matches) {
    $(".module-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".item-search").hasClass("fly-out")) {
            e.preventDefault();
            $(".item-search").addClass("fly-out");
            $(".module-search .form-search .input-text").show();
            $(".module-search .form-search .input-text").focus();
            $(".module-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
    });
}
$(document).on('click', '.module-search .button-close', function (e) {
    e.preventDefault();
    $(".item-search").removeClass("fly-out");
    $('.button-close').remove();
    $(".module-search .form-search .input-text").hide();

});