$(document).ready(function () {
    // listen on window changes
    $(window).resize(function () {
        if ($(window).width() <= 1024) {
            $(".module-quick-add__add-button").removeClass("cb-animate--slide-up");
        } else {
            $(".module-quick-add__add-button").addClass("cb-animate--slide-up");
        }
    })
  
    $(".module-quick-add__add-button").click(function () {
        $(this).hide();
        $(this).siblings(".module-quick-add__actions").addClass("cb-animate--slide-up");
        $(this).siblings(".module-quick-add__actions").show();
    })
  
    $(".module-quick-add__cancel-button").click(function () {
        $(this).parents(".module-quick-add__actions").hide();
        $(this).parents(".module-quick-add__actions").removeClass("cb-animate--slide-up");
        $(this).parents(".module-quick-add__actions").siblings(".module-quick-add__add-button").show();
    })
  })