function triggerSetTallestLiHeightWhenImagesLoaded(parentSelector, itemSelector) {
  const containers = document.querySelectorAll(parentSelector);
  
  containers.forEach(container => {
    const images = container.querySelectorAll('img.yall-loaded, img.owl-lazy');
    let imagesLoadedCount = 0;

    // Function to check if all images are loaded
    function checkIfAllImagesLoaded() {
      if (imagesLoadedCount === images.length) {
        setTallestItemHeight(container, itemSelector);
      }
    }

    // Add event listener to each image
    images.forEach((image) => {
      image.addEventListener('load', () => {
        imagesLoadedCount++;
        checkIfAllImagesLoaded();
      });

      // Check if the image is already loaded
      if (image.complete) {
        imagesLoadedCount++;
        checkIfAllImagesLoaded();
        // image.classList.remove("skeleton")
      }
    });
  });
}

function setTallestItemHeight(container, selector) {
  // console.log('setTallestItemHeight called for', container);
  // Select all elements within the given container
  const listItems = container.querySelectorAll(selector);

  //if container has class module-hot-deals and direct hild have class xm-owl-carousel
  if (container.classList.contains('module-hot-deals') && container.querySelector('.xm-owl-carousel')) {
    // console.log('module-hot-deals and xm-owl-carousel found');
    // reset height of xm-owl-carousel
    container.querySelector('.xm-owl-carousel').style.height = 'auto';
  }

  if (listItems.length === 0) {
    console.log('No li elements found');
    return;
  }

  // Reset the height of all li elements to auto to recalculate their height
  listItems.forEach((li) => {
    li.style.height = 'auto';
  });

  // Find the tallest li element
  let tallestHeightImage = 0;
  let tallestHeight = 0;
  listItems.forEach((li) => {
    const liHeight = li.offsetHeight + parseInt(getComputedStyle(li).marginTop) + parseInt(getComputedStyle(li).marginBottom);

    const liHeightImage = li.querySelector('.product-image-wrapper').offsetHeight + parseInt(getComputedStyle(li).marginTop) + parseInt(getComputedStyle(li).marginBottom);

    if (liHeight > tallestHeight) {
      tallestHeight = liHeight;
    }

    if (liHeightImage > tallestHeightImage) {
      tallestHeightImage = liHeightImage;
    }

    // console.log(liHeight);
    // console.log(liHeightImage);

  });

  // Set all li elements to the height of the tallest one
  listItems.forEach((li) => {
    // li.style.minHeight = `${tallestHeight}px`;
    li.querySelector('.product-image-wrapper').parentElement.style.height = `${tallestHeightImage}px`;
    // also set on its parent where class is item
    // li.parentElement.style.height = `${tallestHeight}px`;

  });
}

function setupOwlCarouselListeners() {
  // Listen for Owl Carousel's initialized event
  // $('.owl-carousel').on('initialized.owl.carousel', function(event) {
  //     console.log('Owl Carousel initialized');
  //     adjustCarouselHeight(event.target);
  // });

  // // Listen for Owl Carousel's resized event
  // $('.owl-carousel').on('resized.owl.carousel', function(event) {
  //     console.log('Owl Carousel resized');
  //     adjustCarouselHeight(event.target);
  // });

  // // Listen for Owl Carousel's refreshed event
  // $('.owl-carousel').on('refreshed.owl.carousel', function(event) {
  //     console.log('Owl Carousel refreshed');
  //     adjustCarouselHeight(event.target);
  // });

  // console.log('setupOwlCarouselListeners called');

  // Listen for Owl Carousel's lazyLoaded event
  $('.xm-owl-carousel').on('loaded.owl.lazy', function(event) {
    // console.log('Owl Carousel image lazy loaded');
    // console.log(event.target);
    adjustCarouselHeight(event.target);
  });
}

function adjustCarouselHeight(carouselElement) {
  triggerSetTallestLiHeightWhenImagesLoaded('.module-hot-deals', '.item');
}

/**
 * Observes the image class 'yall-loaded' and triggers the function 'triggerSetTallestLiHeightWhenImagesLoaded' whenever the class is added.
 *
 * @return {void} This function does not return a value.
 */
function observeImageLoadedClasses() {
  const observer = new MutationObserver((mutationsList, observer) => {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const target = mutation.target;
        if (target.classList.contains('yall-loaded')) {
          // remove class skeleton if it exist
          if (target.classList.contains('skeleton')) {
            target.classList.remove('skeleton');
          }
          // console.log('yall-loaded or owl-lazy added to', target);
          triggerSetTallestLiHeightWhenImagesLoaded('.category-products-listing', '.category-products-listing li.item > .module-category-product-listing');
          triggerSetTallestLiHeightWhenImagesLoaded('.module-hot-deals', '.item');
        }
      }
    }
  });

  const images = document.querySelectorAll('img');
  images.forEach(image => {
    observer.observe(image, { attributes: true });
  });
}

document.addEventListener('DOMContentLoaded', function() {
    observeImageLoadedClasses();
    setupOwlCarouselListeners();
  }
);

window.addEventListener('resize', function() {
  triggerSetTallestLiHeightWhenImagesLoaded('.category-products-listing', '.category-products-listing li.item > .module-category-product-listing');
  triggerSetTallestLiHeightWhenImagesLoaded('.module-hot-deals', '.item');
});