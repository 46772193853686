/**
 * Theme Utilities and Event Handlers
 * Handles contrast calculations and responsive search functionality
 */

// Constants
const MOBILE_BREAKPOINT = 960;

// DOM Elements
const $window = $(window);
const $document = $(document);

/**
 * Updates contrast colors based on theme accent color
 * Calculates appropriate contrast for accessibility
 */
function updateContrastColor() {
    const body = document.body;
    const style = getComputedStyle(body);
    const root = document.documentElement;

    // Get lightness value from CSS variable
    const lightness = parseFloat(style.getPropertyValue('--cb-accent-9-l'));
    
    // Determine contrast type based on background lightness
    const contrastType = lightness <= 50 ? 'light' : 'dark';
    
    // Set contrast color CSS variable
    root.style.setProperty('--cb-accent-contrast', `var(--cb-accent-contrast-${contrastType})`);
}

/**
 * Handles responsive search functionality for mobile devices
 * Adds fly-out behavior when screen width is below breakpoint
 */
function handleMobileSearch() {
    if ($window.width() < MOBILE_BREAKPOINT) {
        $('.nav-search .form-search .button.button-search').on('click', function(e) {
            const $navSearch = $('.nav-search');
            
            if (!$navSearch.hasClass('fly-out')) {
                e.preventDefault();
                
                // Add fly-out class and focus search input
                $navSearch.addClass('fly-out');
                $('.nav-search .form-search .input-text').focus();
                
                // Add close button
                const $closeButton = $('<button>')
                    .addClass('button button-close')
                    .html('<i class="fa fa-times"></i>');
                    
                $(this).after($closeButton);
            }
        });
    }
}

/**
 * Initialize event listeners and observers
 */
function initializeEventListeners() {
    // Watch for style changes on body element
    const observer = new MutationObserver(updateContrastColor);
    observer.observe(document.body, {
        attributes: true,
        attributeFilter: ['style']
    });

    // Window resize handler
    $window.on('resize', handleMobileSearch);
}

/**
 * Document ready handler
 */
$document.ready(function() {
    initializeEventListeners();
    handleMobileSearch();
    updateContrastColor();
});